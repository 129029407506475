import React from "react";
import {Link} from 'gatsby';
import * as Icon from 'react-feather';

import Logo from "../../assets/images/faraya.png"

const Navbar = () => {
    const [menu, setMenu] = React.useState(true)
 
    const toggleNavbar = () => {
        setMenu(!menu)
    }

    React.useEffect(() => {
        let elementId = document.getElementById("header");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0); 
    })
 
    const classOne = menu ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = menu ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <header id="header" className="headroom">
            <div className="startp-nav">
                <div className="container">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <Link to="/" onClick={toggleNavbar} className="navbar-brand">
                            <img src={Logo} alt="logo" />
                        </Link>

                        <button 
                            onClick={toggleNavbar} 
                            className={classTwo}
                            type="button" 
                            data-toggle="collapse" 
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
                            aria-expanded="false" 
                            aria-label="Toggle navigation"
                        >
                            <span className="icon-bar top-bar"></span>
                            <span className="icon-bar middle-bar"></span>
                            <span className="icon-bar bottom-bar"></span>
                        </button>

                        <div className={classOne} id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <Link to="/" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                        Home
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/about" onClick={toggleNavbar} className="nav-link">
                                        About 
                                    </Link> 
                                </li>

                                <li className="nav-item">
                                    <Link to="/services" onClick={toggleNavbar} className="nav-link">
                                        Services 
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    {/* <Link to="#" activeClassName="active" onClick={e => e.preventDefault()} className="nav-link">
                                        Shop <Icon.ChevronDown />
                                    </Link> */}

                                    {/* <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <Link to="/shop" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Shop
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/product-details" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Products Details
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/cart" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Cart
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/checkout" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Checkout
                                            </Link>
                                        </li>
                                    </ul> */}
                                </li>

                                {/* <li className="nav-item">
                                    <Link to="#" onClick={e => e.preventDefault()} className="nav-link">
                                        Blog <Icon.ChevronDown />
                                    </Link>

                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <Link to="/blog-1" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Blog Grid
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/blog-2" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Blog Right Sidebar
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/blog-3" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Blog Grid 2
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/blog-4" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Blog Right Sidebar 2
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/blog-5" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Blog Grid 3
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/blog-6" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Blog Right Sidebar 3
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/blog-details" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                Blog Details
                                            </Link>
                                        </li>
                                    </ul>
                                </li> */}

                                <li className="nav-item">
                                    <Link to="/contact" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        {/* <div className="others-option">
                            <Link to="/cart" className="cart-wrapper-btn">
                                <Icon.ShoppingCart /> 
                                <span>0</span>
                            </Link>

                            <Link to="/contact" className="btn btn-light">
							    Support
                            </Link>

                            <Link to="/login" className="btn btn-primary">
							    Login
                            </Link>
						</div> */}
                    </nav>
                </div>
            </div>
        </header>
    );
}

export default Navbar;